import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "gatsby";

import "./scss/articlesmallcard.scss";

export default function ArticleSmallCard({
  title,
  slug,
  imageSrc,
  restaurantName,
}) {
  return (
    <div className="article-small-card">
      <Link to={`/articles/${slug}`}>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <img src={imageSrc} alt={title} className="small-thumnail" />
          </Grid>
          <Grid item xs={12} sm={7}>
            {restaurantName && (
              <Typography
                component="h2"
                variant="subtitle1"
                align="center"
                display="inline"
                className="restaurant-name"
              >
                {restaurantName}
              </Typography>
            )}
            <div className="restaurant-info">
              <Typography component="h3" variant="subtitle1">
                {title}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Link>
    </div>
  );
}

ArticleSmallCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  imageSrc: PropTypes.string,
  restaurantName: PropTypes.string,
};
